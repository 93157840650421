import { Button } from '../general/Button/Button';
import { GenericError } from '../general/errors/GenericError';

export function NoSession() {
    return (
      <GenericError
        body={'No session ID provided. Please contact the merchant if you\'re trying to make a payment.'}
        actions={
          <Button onClick={() => window.history.back()}>
            Go back
          </Button>
        }
      />
    );
}