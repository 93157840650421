import React, { ReactNode } from 'react';
import { Modal, ModalTitle, ModalActions} from '../Modal/Modal';
import { GenericErrorSvg } from '../icons/svg/GenericErrorSvg';
import styles from './GenericError.module.css';

type Props = {
  title?: string,
  icon?: ReactNode,
  body?: string,
  actions?: ReactNode,
  paymentSessionId?: string;
}

export function GenericError({ title = 'Oops!', icon, body = 'Something went wrong', actions, paymentSessionId }: Props) {
  return (
    <Modal
      paymentSessionId={paymentSessionId}
      icon={icon || <div className={styles.iconWrapper}><GenericErrorSvg /></div>}
    >
      <ModalTitle>{title}</ModalTitle>

      <p>{body}</p>

      {actions && (
        <ModalActions>
          {actions}
        </ModalActions>
      )}
    </Modal>
  );
}