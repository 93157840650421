import React, { PropsWithChildren } from "react";

import styles from './Button.module.css';

export interface ButtonProps extends PropsWithChildren {
    onClick: () => void;
    secondary?: boolean;
}
export const Button: React.FC<ButtonProps> = (props) => {
    const { onClick, secondary, children } = props;

    const secondaryClass = secondary ? styles.secondary : '';
    return (
        <button 
            onClick={onClick}
            className={`${styles.button} ${secondaryClass}`}
        >
            {children}
        </button>
    );
};