import React from 'react'

export function SucceededPaymentSvg(): JSX.Element {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20C0 8.98438 8.90625 0 20 0C31.0156 0 40 8.98438 40 20C40 31.0938 31.0156 40 20 40C8.90625 40 0 31.0938 0 20ZM28.9844 16.5625C29.8438 15.7031 29.8438 14.375 28.9844 13.5156C28.125 12.6562 26.7969 12.6562 25.9375 13.5156L17.5 21.9531L13.9844 18.5156C13.125 17.6562 11.7969 17.6562 10.9375 18.5156C10.0781 19.375 10.0781 20.7031 10.9375 21.5625L15.9375 26.5625C16.7969 27.4219 18.125 27.4219 18.9844 26.5625L28.9844 16.5625Z"
        fill="#399847"
      />
    </svg>
  )
}