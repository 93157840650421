/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './Modal.module.css';

export interface ModalProps extends PropsWithChildren {
    icon?: ReactNode
    paymentSessionId?: string;
}
export function Modal({ children, paymentSessionId, icon}: ModalProps) {
    return (
        <div className={styles.container}>
            <div className={styles.dialog}>
                <div className={styles.content}>
                    {icon}
                    {children}
                </div>
                {paymentSessionId && (
                    <div className={styles.footer}>
                        Session ID: {paymentSessionId}
                    </div>
                )}
            </div>
        </div>
    );
};


export interface ModalTitleProps extends PropsWithChildren {
}
export const ModalTitle: React.FC<ModalTitleProps> = (props) => {
    const { children } = props;
    return (
        <h3 className={styles.title}>
            {children}
        </h3>
    );
};

export interface ModalActionsProps extends PropsWithChildren {
}
export const ModalActions: React.FC<ModalActionsProps> = (props) => {
    const { children } = props;
    return (
        <div className={styles.actions}>
            {children}
        </div>
    );
};