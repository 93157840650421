import React from 'react'

export function GenericErrorSvg(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 390 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100%" height="100%" fill="white" />
      <path
        d="M195 43.3125C146.836 43.3125 107.812 82.3359 107.812 130.5C107.812 178.664 146.836 217.688 195 217.688C243.164 217.688 282.188 178.664 282.188 130.5C282.188 82.3359 243.164 43.3125 195 43.3125ZM195 206.438C153.164 206.438 119.062 172.688 119.062 130.5C119.062 89.0156 152.812 54.5625 195 54.5625C236.484 54.5625 270.938 88.6641 270.938 130.5C270.938 172.336 236.836 206.438 195 206.438ZM228.047 106.242C229.805 104.484 229.805 102.023 228.047 100.266L225.234 97.4531C223.477 95.6953 221.016 95.6953 219.258 97.4531L195 121.711L170.391 97.4531C168.984 95.6953 166.172 95.6953 164.414 97.4531L161.602 100.266C159.844 102.023 159.844 104.484 161.602 106.242L185.859 130.5L161.602 155.109C159.844 156.516 159.844 159.328 161.602 161.086L164.414 163.898C166.172 165.656 168.984 165.656 170.391 163.898L195 139.641L219.258 163.898C221.016 165.656 223.477 165.656 225.234 163.898L228.047 161.086C229.805 159.328 229.805 156.516 228.047 155.109L203.789 130.5L228.047 106.242Z"
        fill={'#212B36'}
      />
    </svg>
  )
}