// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { PaymentSession } from '../session/PaymentSession';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { NoSession } from '../session/NoSession';
import { AbandonSessionMutation } from '../../generated/gql/graphql';

const gqlUrl = import.meta.env.VITE_APP_GQL_API_URL || `fallback`;

const client = new ApolloClient({
  uri: gqlUrl,
  cache: new InMemoryCache(),
});

const paymentSessionId = (new URLSearchParams(window.location.hash.substring(1))).get('pid');

export const App: React.FC = () => {
    if (!paymentSessionId) {
        return (
            <NoSession/>
        );
    }
    return (
        <ApolloProvider client={client}>
            <PaymentSession id={paymentSessionId}/>
        </ApolloProvider>
    );
}

export default App;

const ABANDON_SESSION_QUERY = gql`
    mutation AbandonSession($id: ID!) {
        paymentSessionAbandon(id: $id) {
            id
            state
            cancelUrl
        }
    }
`;

const abandonSession = async (paymentSessionId: string) => {
    const session = await client.mutate<AbandonSessionMutation>({
        mutation: ABANDON_SESSION_QUERY,
        variables: {
            id: paymentSessionId
        }
    });
    if (!session.data) {
        throw new Error('No response from attempt to abandon session');
    }
    window.location.href = session.data.paymentSessionAbandon.cancelUrl;
};

const error = (new URLSearchParams(window.location.search)).get('error');
if (paymentSessionId && error === 'tl_hpp_abandoned') {
    abandonSession(paymentSessionId);
}
