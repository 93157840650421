import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import { PaymentAttemptState, PaymentSessionAbandonMutation, PaymentSessionInfo, PollForPaymentInfoQuery, RetryPaymentAttemptMutation } from '../../generated/gql/graphql';
import { Modal, ModalActions, ModalTitle } from "../general/Modal/Modal";
import { LoadingIndicator } from "../general/Loading/LoadingIndicator";
import { Button } from "../general/Button/Button";
import { GenericError } from '../general/errors/GenericError';
import { SucceededPaymentSvg } from '../general/icons/svg/SucceededPaymentSvg';

const POLL_FOR_PAYMENT_INFO = gql`
query PollForPaymentInfo($id: ID!) {
    paymentSessionInfo(id: $id) {
        id
        cancelUrl
        nextActionUrl
        shopDomain
        state
        currentAttempt {
            id
            state
            truelayerPaymentId
            hppRedirectUrl
        }
    }
}
`;

const RETRY_PAYMENT_ATTEMPT = gql`
mutation RetryPaymentAttempt($id: ID!) {
    paymentSessionRetry(id: $id) {
        id
        cancelUrl
        nextActionUrl
        shopDomain
        state
        currentAttempt {
            id
            state
            truelayerPaymentId
            hppRedirectUrl
        }
    }
}
`;

const ABANDON_SESSION = gql`
mutation paymentSessionAbandon($id: ID!) {
    paymentSessionAbandon(id: $id) {
        id
    }
}
`;

let fallbackSession: PaymentSessionInfo|null = null;

export interface PaymentSessionProps {
    id: string;
}
export const PaymentSession: React.FC<PaymentSessionProps> = (props) => {
    const { id } = props;


    const { loading: loadingPoll, data, error, refetch } = useQuery<PollForPaymentInfoQuery>(POLL_FOR_PAYMENT_INFO, {
        variables: {
            id,
        }
    });

    const [mutateRetry, { loading: loadingMutate }] = useMutation<RetryPaymentAttemptMutation>(RETRY_PAYMENT_ATTEMPT, {
        variables: {
            id,
        }
    });

    const [mutateAbandon, { loading: loadingAbandon }] = useMutation<PaymentSessionAbandonMutation>(ABANDON_SESSION, {
        variables: {
            id,
        }
    });

    const loading = loadingPoll || loadingAbandon || loadingMutate;

    const [polling, setPolling] = useState(false);
    const startPolling = () => {
        if (!polling) {
            setPolling(true);
        }
    };
    const stopPolling = () => {
        if (polling) {
            setPolling(false);
        }
    };

    useEffect(() => {
        if (!polling) {
            return () => {
                //
            };
        }
        const handle = setInterval(() => {
            void refetch()
        }, 5000);
        return () => {
            clearInterval(handle);
        };
    }, [polling]);

    if (loading) {
        return <LoadingIndicator/>;
    }

    if (error || !data) {
        const handleReturnToMerchant = async () => {
            // Try to abandon first
            try {
                await mutateAbandon();
            } catch (e) {
                // Ignore errors
            }
            // Return to merchant if known
            if (data && data.paymentSessionInfo.cancelUrl) {
                window.location.href = data?.paymentSessionInfo.cancelUrl;
                return;
            }
            // If we managed to retrieve the session previously we should have cached the details
            if (fallbackSession) {
                window.location.href = fallbackSession.cancelUrl || `https://${fallbackSession.shopDomain}/`;
                return;
            }
            // Last ditch attempt, try to get them back to merchant using browser history.
            setTimeout(() => {
                // Couldn't go back for some reason
                alert(`Unable to return you to the merchant site. Please close this page and visit the merchant site.`);
            }, 3000);
            if (window.history.back) {
                window.history.back();
                return;
            }
            window.history.go(-1);
        };
        return (
            <GenericError
                paymentSessionId={id}
                title={'Payment failed'}
                body={'Something went wrong and your payment could not be completed.'}
                actions={<Button onClick={handleReturnToMerchant}>Return to merchant</Button>}
            />
        );
    }
    const session = data.paymentSessionInfo;
    fallbackSession = session;

    const handleReturnToMerchant = async () => {
        await mutateAbandon();
        window.location.href = session.cancelUrl;
    };
    const handleRetryPayment = async () => {
        setPolling(true);
        const { data } = await mutateRetry();
        const url = data?.paymentSessionRetry.currentAttempt.hppRedirectUrl;
        if (url) {
            window.location.href = url;
        }
    };

    if (session.state === 'CANCELLED' || session.state === 'FAILED') {
        stopPolling();
        if (session.state === 'CANCELLED') {
            // Return immediately
            handleReturnToMerchant();
        }
        return (
          <GenericError
            paymentSessionId={id}
            title={'Payment failed'}
            body={session.state === 'CANCELLED' ? 'You cancelled this payment.' : 'Something went wrong and your payment could not be completed.'}
            actions={<Button onClick={handleReturnToMerchant}>Return to merchant</Button>} />
        );
    }
    if (session.state === 'NEEDS_RETRY') {
        stopPolling();
        return (
          <GenericError
            paymentSessionId={id}
            title={'Payment failed'}
            body={"Your payment didn't go through."}
            actions={<>
                  <Button onClick={handleReturnToMerchant} secondary>Cancel</Button>
                  <Button onClick={handleRetryPayment}>Try again</Button>
              </>}
          />
        );
    }
    if (session.state === 'SUCCESS' || session.state === 'PENDING') {
        stopPolling();
        if (!session.nextActionUrl) {
            // TODO
            throw new Error('session was successful, but no next action url captured');
        }
        const handleRedirect = () => {
            window.location.href = session.nextActionUrl!;
        };
        handleRedirect();

        return (
            <Modal paymentSessionId={id} icon={<SucceededPaymentSvg />}>
                <ModalTitle>Payment successful</ModalTitle>
                <ModalActions>
                    <Button onClick={() => handleRedirect()}>Continue</Button>
                </ModalActions>
            </Modal>
        );
    }


    if (session.currentAttempt.state === PaymentAttemptState.InProgress) {
        // Can proceed to auth
        stopPolling();
        const handleRedirect = () => {
            window.location.href = session.currentAttempt.hppRedirectUrl;
        };
        handleRedirect();

        return (
            <Modal paymentSessionId={id}>
                <ModalTitle>Redirecting to payment</ModalTitle>
                <ModalActions>
                    <Button onClick={() => handleRedirect()}>Continue</Button>
                </ModalActions>
            </Modal>
        );
    }

    if (session.state === 'IN_PROGRESS') {
        startPolling();
        return <LoadingIndicator />;
    }
    throw new Error(`Unsupported session state: ${session.state}`);
};