import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import './styles.css';
import App from './app/app';

ReactDOM
    .createRoot(document.getElementById('root') as HTMLElement)
    .render(
        <StrictMode>
            <App />
        </StrictMode>
    );


// Expected env variables
// https://vitejs.dev/guide/env-and-mode.html
declare global {
    interface ImportMeta {
        readonly env: {
            // standard
            BASE_URL: string;
            DEV: boolean;
            MODE: 'development'|'production';
            PROD: boolean;
            SSR: boolean;
            // Node
            NODE_ENV?: string;
            // custom (defined in .env)
            VITE_APP_GQL_API_URL?: string;
        }
    }
}