/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type EnvironmentSettingsInfo = {
  __typename?: 'EnvironmentSettingsInfo';
  id: Scalars['String']['output'];
  kmsSigningKeyId?: Maybe<Scalars['String']['output']>;
  providerCountryFilter: Array<Scalars['String']['output']>;
  providerCustomerSegmentFilter: Array<Scalars['String']['output']>;
  providerDenylistFilter: Array<Scalars['String']['output']>;
  pubKey?: Maybe<Scalars['String']['output']>;
  test: Scalars['Boolean']['output'];
  truelayerClientId?: Maybe<Scalars['String']['output']>;
  truelayerClientSecret?: Maybe<Scalars['String']['output']>;
  truelayerClientSecretPresent?: Maybe<Scalars['Boolean']['output']>;
  truelayerKeyId?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
  webhookUrl: Scalars['String']['output'];
};

export type EnvironmentSettingsInput = {
  kmsSigningKeyId?: InputMaybe<Scalars['String']['input']>;
  providerCountryFilter: Array<Scalars['String']['input']>;
  providerCustomerSegmentFilter: Array<Scalars['String']['input']>;
  providerDenylistFilter: Array<Scalars['String']['input']>;
  truelayerClientId?: InputMaybe<Scalars['String']['input']>;
  truelayerClientSecret?: InputMaybe<Scalars['String']['input']>;
  truelayerKeyId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  paymentSessionAbandon: PaymentSessionInfo;
  paymentSessionRetry: PaymentSessionInfo;
  settingsUpdate: SettingsInfo;
};


export type MutationPaymentSessionAbandonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPaymentSessionRetryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSettingsUpdateArgs = {
  input: SettingsUpdateInput;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentAttemptInfo = {
  __typename?: 'PaymentAttemptInfo';
  hppRedirectUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  state: PaymentAttemptState;
  truelayerPaymentId: Scalars['String']['output'];
};

export enum PaymentAttemptState {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type PaymentSessionInfo = {
  __typename?: 'PaymentSessionInfo';
  cancelUrl: Scalars['String']['output'];
  currentAttempt: PaymentAttemptInfo;
  id: Scalars['ID']['output'];
  nextActionUrl?: Maybe<Scalars['String']['output']>;
  shopDomain: Scalars['String']['output'];
  state: PaymentSessionState;
};

export enum PaymentSessionState {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NeedsRetry = 'NEEDS_RETRY',
  Pending = 'PENDING',
  Success = 'SUCCESS'
}

export type ProviderEntryInfo = {
  __typename?: 'ProviderEntryInfo';
  country: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  iconUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logoUrl: Scalars['String']['output'];
};

export type ProviderInfo = {
  __typename?: 'ProviderInfo';
  live: Array<ProviderEntryInfo>;
  test: Array<ProviderEntryInfo>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  paymentSessionInfo: PaymentSessionInfo;
  providers: ProviderInfo;
  settings: SettingsInfo;
  testItem?: Maybe<TestItem>;
};


export type QueryPaymentSessionInfoArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTestItemArgs = {
  id: Scalars['ID']['input'];
};

export type SettingsInfo = {
  __typename?: 'SettingsInfo';
  liveSettings: EnvironmentSettingsInfo;
  testSettings: EnvironmentSettingsInfo;
};

export type SettingsUpdateInput = {
  liveSettings?: InputMaybe<EnvironmentSettingsInput>;
  testSettings?: InputMaybe<EnvironmentSettingsInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']['output']>;
};

export type TestItem = {
  __typename?: 'TestItem';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AbandonSessionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AbandonSessionMutation = { __typename?: 'Mutation', paymentSessionAbandon: { __typename?: 'PaymentSessionInfo', id: string, state: PaymentSessionState, cancelUrl: string } };

export type PollForPaymentInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PollForPaymentInfoQuery = { __typename?: 'Query', paymentSessionInfo: { __typename?: 'PaymentSessionInfo', id: string, cancelUrl: string, nextActionUrl?: string | null, shopDomain: string, state: PaymentSessionState, currentAttempt: { __typename?: 'PaymentAttemptInfo', id: string, state: PaymentAttemptState, truelayerPaymentId: string, hppRedirectUrl: string } } };

export type RetryPaymentAttemptMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RetryPaymentAttemptMutation = { __typename?: 'Mutation', paymentSessionRetry: { __typename?: 'PaymentSessionInfo', id: string, cancelUrl: string, nextActionUrl?: string | null, shopDomain: string, state: PaymentSessionState, currentAttempt: { __typename?: 'PaymentAttemptInfo', id: string, state: PaymentAttemptState, truelayerPaymentId: string, hppRedirectUrl: string } } };

export type PaymentSessionAbandonMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PaymentSessionAbandonMutation = { __typename?: 'Mutation', paymentSessionAbandon: { __typename?: 'PaymentSessionInfo', id: string } };


export const AbandonSessionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AbandonSession"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentSessionAbandon"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"cancelUrl"}}]}}]}}]} as unknown as DocumentNode<AbandonSessionMutation, AbandonSessionMutationVariables>;
export const PollForPaymentInfoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"PollForPaymentInfo"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentSessionInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"cancelUrl"}},{"kind":"Field","name":{"kind":"Name","value":"nextActionUrl"}},{"kind":"Field","name":{"kind":"Name","value":"shopDomain"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"currentAttempt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"truelayerPaymentId"}},{"kind":"Field","name":{"kind":"Name","value":"hppRedirectUrl"}}]}}]}}]}}]} as unknown as DocumentNode<PollForPaymentInfoQuery, PollForPaymentInfoQueryVariables>;
export const RetryPaymentAttemptDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RetryPaymentAttempt"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentSessionRetry"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"cancelUrl"}},{"kind":"Field","name":{"kind":"Name","value":"nextActionUrl"}},{"kind":"Field","name":{"kind":"Name","value":"shopDomain"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"currentAttempt"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"state"}},{"kind":"Field","name":{"kind":"Name","value":"truelayerPaymentId"}},{"kind":"Field","name":{"kind":"Name","value":"hppRedirectUrl"}}]}}]}}]}}]} as unknown as DocumentNode<RetryPaymentAttemptMutation, RetryPaymentAttemptMutationVariables>;
export const PaymentSessionAbandonDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"paymentSessionAbandon"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"paymentSessionAbandon"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<PaymentSessionAbandonMutation, PaymentSessionAbandonMutationVariables>;